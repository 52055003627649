import { Injectable } from '@angular/core';

import { AccountLicenseService } from 'rev-shared/security/AccountLicense.Service';
import { MediaFeaturesService } from 'rev-shared/media/MediaFeatures.Service';
import { SecurityContextService } from 'rev-shared/security/SecurityContext.Service';

@Injectable({
	providedIn: 'root'
})
export class RevIQService {

	constructor(
		private MediaFeaturesService: MediaFeaturesService,
		private accountLicense: AccountLicenseService,
		private SecurityContext: SecurityContextService
	) { }

	public isRevIQEligible(accountId: string): Promise<boolean> {
		return this.MediaFeaturesService.getFeatures(accountId)
			.then(features => features.transcriptionSettings?.isEnabledRevTranscription);
	}

	public isRevIQEligibleAndAuthorized(accountId: string): Promise<boolean> {
		return this.isRevIQEligible(accountId)
			.then(isRevIQEligible => isRevIQEligible && this.SecurityContext.checkAuthorization('media.useRevIq'));
	}

	public isRevIQEligibleWithValidAiCredits(accountId: string): Promise<boolean> {
		return Promise.all([
			this.isRevIQEligibleAndAuthorized(accountId),
			this.accountLicense.updateLicenseHoursValid()
		])
			.then(([isRevIQEligible]) => this.accountLicense.aiCreditsValid && isRevIQEligible);
	}
}
