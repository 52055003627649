<div class="modal-header">
	<h4>
		{{ title }}
	</h4>
	<button type="button" class="close btn btn-transparent" (click)="hide()" [attr.aria-label]="'Close' | translate">
		<span class="glyphicons remove_2"></span>
	</button>
</div>
<div class="modal-body">
	{{ message }}
</div>
<div class="modal-body">
	<div><img [src]="imageUrl"/></div>
	<a href="https://storyset.com/people" target="_blank">{{ 'Event_Producer_ArtBy' | translate: { '0': 'storyset' } }}</a>
</div>
<div class="modal-footer">
	<button vbUiBtnPrimary (click)="hide()">
		{{ okButtonText || 'Ok' | translate }}
	</button>
</div>
