import { ResolvableLiteral, Transition } from '@uirouter/angular';

import { ThemeService } from 'rev-portal/branding/Theme.Service';

import { IVbNg2StateDeclaration } from 'rev-shared/ts-utils/IVbNg2StateDeclaration';
import { PORTAL_ROOT_STATE } from 'rev-shared/bootstrap/AppStateNames';
import { Portal404Component } from 'rev-shared/security/components/Portal404.Component';
import { Unauthorized401Component } from 'rev-shared/security/components/Unauthorized401.Component';
import { UserContextService } from 'rev-shared/security/UserContext.Service';

const params401 = {
	reason: {}
};

export function getPortalBaseStates(componentName?: any, isAbstract?: boolean, resolves?: ResolvableLiteral[], ignoreStandalone401?: boolean): IVbNg2StateDeclaration[] {
	return [
		{
			name: PORTAL_ROOT_STATE,
			abstract: isAbstract,
			component: componentName,
			allowGuestAccess: true,
			allowPublicVideoAccess: true,
			allowRegisteredGuestAccess: true,
			resolve: [
				{
					token: 'accountId',
					deps: [
						UserContextService
					],
					resolveFn: (UserContext: UserContextService) => UserContext.getAccount().id
				},
				{
					token: 'initTheme',
					deps: [
						ThemeService
					],
					resolveFn: (ThemeService: ThemeService) => {
						if (!ThemeService.accentFontColor) {
							ThemeService.initialize();
						}
					}
				},
				{
					token: 'isUniversalEcdn',
					deps: [
						UserContextService
					],
					resolveFn: (UserContext: UserContextService) => UserContext.getAccount().isUniversalEcdn
				},
				...(resolves || [])
			]
		},
		!ignoreStandalone401 && {
			name: '401', //standalone 401
			url: '/401',
			component: Unauthorized401Component,
			allowAllAccess: true,
			params: params401,
			resolve: [
				{
					token: 'reason',
					deps: [
						Transition
					],
					resolveFn: (transition: Transition) => transition.params().reason
				}
			]
		},
		{
			name: 'portal.401', //401 framed within the portal
			url: '/401-unauthorized',
			component: Unauthorized401Component,
			allowAllAccess: true,
			params: params401,
			resolve: [
				{
					token: 'reason',
					deps: [
						Transition
					],
					resolveFn: (transition: Transition) => transition.params().reason
				}
			]
		},
		{
			name: 'portal.404', //401 framed within the portal
			url: '/404',
			component: Portal404Component,
			allowAllAccess: true,
		}
	].filter(Boolean);
}
