export interface IRgb {
	r: number;
	g: number;
	b: number;
}

export function setOpacity(hex: string, opacity: number): string {
	const { r, g, b } = parseHexColor(hex);
	return getRgba(r, g, b, opacity);
}

export function getRgba(r: number, g: number, b: number, a: number): string {
	return `rgba(${r}, ${g}, ${b}, ${a})`;
}

/**
 * based on color's brightness, returns either black or white transparent overlay color
 */
export function findOverlayColor(hex: string): string {
	const brightness = getBrightness(hex);

	if(brightness > 0.5) {
		return getRgba(0, 0, 0, 0.1);
	}

	return getRgba(255, 255, 255, 0.1);
}

function getBrightness(hex: string): number {
	const { r, g, b } = parseHexColor(hex);
	return Math.max(r, g, b) / 255;
}

function parseHexColor(hex: string): IRgb {
	const result = hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
		.substring(1)
		.match(/.{2}/g)
		.map(x => parseInt(x, 16));

	return result && {
		r: result[0],
		g: result[1],
		b: result[2]
	};
}
