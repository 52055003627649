import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { UserLocalIPService } from 'rev-shared/security/UserLocalIP.Service';
import { escape } from 'rev-shared/util';
import { getViewerId, getViewerIdInfo } from 'rev-shared/viewerId/ViewerIdHelper';

import { VideoOverlay, VIDEO_OVERLAY_TYPE } from './VideoOverlay';

export function constructViewerIdOverlay(
	accViewerIdSettings: any,
	userContext: UserContextService,
	userLocalIPService: UserLocalIPService,
	localViewerIdEnabled: boolean,
	customText?: string
): VideoOverlay {

	if (!accViewerIdSettings) {
		return;
	}

	const viewerId = getViewerId(userContext, userLocalIPService);
	const viewerIdInfo = getViewerIdInfo(accViewerIdSettings, viewerId, localViewerIdEnabled, userContext, customText);

	if (!viewerIdInfo) {
		return;
	}

	const overlayInformation = [];
	const overlayFirstLine = [];
	let y = 20;
	if (viewerIdInfo.fullName) {
		overlayFirstLine.push(viewerIdInfo.fullName);
	}
	if (viewerIdInfo.id) {
		overlayFirstLine.push(viewerIdInfo.id);
	}
	if (overlayFirstLine) {
		overlayInformation.push(`${ formatViewerIdSvgInfo('viewerIdFLine', 0, y, overlayFirstLine.join(' / ')) }`);
		y += 20;
	}
	overlayInformation.push(`${ formatViewerIdSvgInfo('viewerIdCMsg', 0, y, viewerIdInfo.customMsg) }`);
	const id = Math.random();
	return {
		id,
		content: `
			<svg viewBox="200 0 2300 1500" id="diagonal-overlay-${id}" width='100%' height='100%'>
				<defs>
					<pattern id="pattern-${id}" patternUnits='userSpaceOnUse' width='22%' height='10%' patternTransform='scale(3.1) rotate(-20)'>
						${overlayInformation.join('\n')}
					</pattern>
				</defs>
				<rect width='800%' height='800%' transform='translate(0,-1800)' fill="url(#pattern-${id})"></rect>
			</svg>`,
		forcedOverlayRecreation: true,
		recreationTriggerIds: ['viewerIdFLine', 'viewerIdCMsg'],
		type: VIDEO_OVERLAY_TYPE.VIEWER_ID
	};
}

function formatViewerIdSvgInfo(id: string, x: number, y: number, info: string): string {
	return `<text id="${id}" stroke-width=".2" stroke="black" stroke-opacity=".8" fill-opacity=".8" letter-spacing="1" fill="white" x="${x}" y="${y}">${escape(info)}</text>`;
}

export function refreshViewerIdOverlay(video: any, prevPlayerSize: { width: number, height: number }, currentPlayerSize: { width: number, height: number }): void {
	if (!video.videoOverlays?.some(v => v.type === VIDEO_OVERLAY_TYPE.VIEWER_ID)) {
		return;
	}
	if (prevPlayerSize?.width !== currentPlayerSize.width && prevPlayerSize?.height !== currentPlayerSize.height) {
		const prevOverlays = video.videoOverlays;
		video.videoOverlays = prevOverlays.filter(vo => vo.type !== VIDEO_OVERLAY_TYPE.VIEWER_ID);
		setTimeout(() => {
			video.videoOverlays = prevOverlays.map(m => m);
		});
	}
}
