<form fileUpload class="form-horizontal form-upload-files" *ngIf="chunkUploadSizeBytes" method="post" enctype="multipart/form-data" [singleFileUploads]="true" [maxChunkSize]="chunkUploadSizeBytes" (onAdd)="addFile($event)" (submit)="noop()">

	<div layout="row" flex-justify="space">
		<video-transcribe-and-ai-metadata name="uploadTrabscribeAndAiMetadata" [mediaFeatures]="features" [accountId]="accountId" [containerClass]="{dropdownMenu: 'upload-child-dropdown-menu'}" ngModel (ngModelChange)="onAddPostUploadActions($event)">
		</video-transcribe-and-ai-metadata>
		@if (features.enable360VideoUploading) {
		<div class="threesixty-container">
			<vb-toggle-button *ngIf="features.enable360VideoUploading" name="is360Video" [ariaLabel]="'Uploads_360Video' | translate" [(ngModel)]="is360Video">
			</vb-toggle-button>
			<span class="vb-icon vb-icon-360-indicator"></span>
			{{ 'Uploads_360Video' | translate }}
		</div>
		}
	</div>

	<div class="upload-file-btn-wrapper file-upload-drop-zone">
		<div class="btn-file">
			<div class="drag-and-drop-wrapper">
				<span>{{ 'Uploads_DragAndDrop' | translate }}</span>
				<span class="drag-drop-helper">{{ 'Uploads_DragAndDrop_Helper' | translate }}</span>
				<button type="button" vbUiBtnPrimaryNgx>{{ 'Uploads_SelectFiles' | translate }}</button>
			</div>
			<span class="vb-icon vb-icon-upload file-upload-icon"></span>
			<input type="file" accept=".mkv,.m4a,.mts,video/mp4,video/x-m4v,video/x-flv,video/*,audio/mpeg,audio/mp3,audio/mpeg3,audio/x-mpeg-3" [attr.aria-label]="'AddFile' | translate " multiple="multiple" name="upload">
		</div>
	</div>
</form>

<ng-container *ngIf="(UploadFileService.videos$| async) as videos">
	<div class="scrollable-area-wrapper" [hidden]="!(videos.length)">
		<ol class="list-unstyled scrollable-area">
			<li *ngFor="let video of videos" class="upload-file-wrapper" [ngClass]="{ 'upload-complete': video.status.complete,
							'upload-error': video.status.error || video.status.invalidFile || video.status.exceededMaxFileSize,
							'upload-pending': !video.id}">
				<div class="upload-file">
					<h4 layout="row" layout-wrap="false">
						<a [hidden]="!(!video.status.error && video.id)" uiSref="portal.video-settings" [uiParams]="{videoId: video.id}" class="upload-title btn-icon-left text-ellipsis type-bold" flex="fill">
							{{ video.title }}
						</a>
						<span class="upload-title text-ellipsis type-normal" flex="fill" [hidden]="!video.status.error">
							<span class="glyphicons circle_exclamation_mark margin-right-3"></span>
							{{ video.title }}
						</span>
						<span class="upload-title text-ellipsis type-normal" flex="fill" [hidden]="video.id || video.status.error">
							{{ video.title }}
						</span>

						<video-360-indicator class="margin-left" *ngIf="video.is360">
						</video-360-indicator>

						<vb-loading-spinner class="margin-left" [hidden]="!video.status.creating"></vb-loading-spinner>
					</h4>

					<p class="upload-date" [hidden]="!video.status.complete || video.status.error">
						{{ video.uploadDate | vbDateTimeMedium }}
						<span class="glyphicons circle_ok"></span>
					</p>

					<p class="error-msg" [hidden]="!video.status.error">{{ 'UploadError' | translate }}
						<button type="button" class="btn-remove btn-transparent" (click)="clearVideo(video)" [attr.title]="'Remove' | translate">
							<span class="glyphicons circle_remove"></span>
						</button>
					</p>

					<p class="error-msg" [hidden]="!video.status.invalidFile">{{ 'UploadInvalidFileType' | translate }}
						<button type="button" class="btn-remove btn-transparent" (click)="clearVideo(video)" [attr.title]="'Remove' | translate">
							<span class="glyphicons circle_remove"></span>
						</button>
					</p>

					<p class="error-msg" [hidden]="!video.status.exceededMaxFileSize">{{ 'UploadExceededMaxFileSize' | translate }}
						<button type="button" class="btn-remove btn-transparent" (click)="clearVideo(video)" [attr.title]="'Remove' | translate">
							<span class="glyphicons circle_remove"></span>
						</button>
					</p>

					<div class="progress-wrapper with-cancel mini" [hidden]="video.status.complete || video.status.error || video.status.invalidFile || video.status.exceededMaxFileSize">
						<div class="progress">
							<div class="progress-bar" role="progressbar" [ngStyle]="{ width: video.progress * 100 + '%' }">
								<span class="visually-hidden">{{ ( video.progress * 100) | number: 0 }}% {{ 'Complete' | translate }}</span>
							</div>
						</div>
						<button class="btn-remove btn-transparent" (click)="cancelUpload(video, $event)" [hidden]="video.status.complete">
							<span class="glyphicons circle_remove"></span>
						</button>
					</div>

					<div class="upload-option-icons">
						<span [hidden]="!video.videoUploadPostActions?.metadataGenerationFields?.length" class="vb-icon vb-icon-sparkle"></span>
						<span [hidden]="!video.videoUploadPostActions?.transcribeLanguageId" class="glyphicons translate"></span>
					</div>

				</div>
			</li>
		</ol>
	</div>
</ng-container>

<div class="menu-footer group">
	<button type="button" class="btn-transparent float-left text-color-inherit" [hidden]="!((UploadFileService.videos$ | async)?.length)" (click)="dismissAllUploads()">
		{{ 'ClearCompleted' | translate }}
	</button>
	<a class="float-right text-color-inherit" *vbAuthorizationKey="'media.add'" uiSref="portal.media.uploads">
		{{ 'Uploads_SeeAllUploads' | translate }}
	</a>
	<div class="util-white-space-normal util-clear-both alert-danger upload-viewing-hours-warning" [hidden]="!(viewingHoursUnavailable)">
		{{ 'Uploads_ViewingHoursNotAvailable' | translate }}
	</div>
</div>
