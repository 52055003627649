<div class="dropdown" dropdown [insideClick]="true" #templatesDropdown="bs-dropdown">
	<button type="button" [ngClass]="styles.dropdownToggle" vbUiBtnPrimaryNgx dropdownToggle class="dropdown-toggle">
		{{ 'Templates' | translate }}
	</button>

	<vb-ui-tooltip class="margin-left-5" [iconName]="'circle_question_mark'" [placement]="'right'" [text]="tooltipMsg">
	</vb-ui-tooltip>

	<div [ngClass]="styles.dropdownMenu" *dropdownMenu class="dropdown-menu align-right">
		<template-picker [templatesService]="templatesService" (onTemplateSelected)="applyTemplate($event)" (closeMenu)="templatesDropdown.hide()"></template-picker>
	</div>
</div>
