import { BootstrapContext } from 'rev-shared/bootstrap/BootstrapContext';

export const defaultLogo = {
	name: 'default-logo',
	url:  `${BootstrapContext.baseCdnUrl}/img/vbrick-product.png`,
	ecdnUrl: `${BootstrapContext.baseCdnUrl}/img/universal-ecdn.png`
};

export const defaultFont = {
	name: 'Roboto',
	url:  `${BootstrapContext.baseCdnUrl}/font/roboto-regular-webfont.woff`
};

export const defaultColors = {
	accentColor: '#108dda',
	accentFontColor: '#fff',
	featuredVideoBackgroundColor: '#141414',
	primaryColor:  '#141414',
	primaryFontColor: '#cbcbcb',
	channelFont: '#fff'
};

export const defaultHeaderColors = {
	background: '#1a1a1a',
	font: '#d1d1d1'
};

export const defaultHelpUri = 'https://revdocs.vbrick.com/docs';
